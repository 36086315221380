<template>
  <div>
    <div v-if="routeQuery.actionType === 'modifier' && routeQuery.isUpdate === 'false' && !isError">
      <b-breadcrumb class="mb-2">
        <b-breadcrumb-item to="/orders/recurring">
          Packages
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link
            :to="{
              name: 'commande',
              params: { id: this.$route.query.packageId }

            }"
          >Commandes</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link
            :to="{
              name: 'admin-orders-recurring-details',
              params: { id: this.$route.query.orderId },
              query: { packageId: this.$route.query.packageId , service: this.$route.query.service },

            }"
          >Détails</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          Feedback
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <div v-if="routeQuery.actionType === 'modifier' && routeQuery.isUpdate === 'true' && !isError">
      <b-breadcrumb class="mb-2">
        <b-breadcrumb-item to="/work/providers/recommandations">
          Packages
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link
            :to="{
              name: 'recommandation',
              params: { id: this.$route.query.packageId }

            }"
          >Commandes</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link
            :to="{
              name: 'recommandation-orders-details',
              params: { id: this.$route.query.orderId },
              query: { packageId: this.$route.query.packageId , service: this.$route.query.service },

            }"
          >Détails</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          Feedback
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <div
      v-if="isError"
      class="d-flex flex-column justify-content-center mt-5 text-center"
      style="padding-top: 30vh"
    >
      <h1 class="text-danger">
        <feather-icon
          icon="XCircleIcon"
          size="100"
          is-contract-file-downloading
        />
      </h1>
      <p class="font-medium-1 text-danger font-weight-normal">
        Erreur de chargement du fichier
      </p>
    </div>

    <div
      v-if="routeQuery.makeActionOnPage === 'true'"
      class="d-flex align-items-left ml-5 mt-1 font-medium-2"
    >
      <b-button
        v-if="routeQuery.actionType === 'signature' && routeQuery.isSigned === 'false' && !isError"
        v-b-modal.modal-sign-contrat
        variant="primary"
      >
        Signer
      </b-button>

      <b-button
        v-if="routeQuery.actionType === 'modifier' && routeQuery.isUpdate === 'false' && !isError"
        variant="primary"
        @click="modifyFeedback()"
      >
        Modifier
      </b-button>
    </div>

    <br>

    <b-embed
      type="iframe"
      aspect="16by9"
      :src="routeQuery.pdf"
      allowfullscreen
    />

    <b-modal
      id="modal-sign-contrat"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Signer le contrat"
    >

      <VueSignaturePad
        ref="signaturePad"
        width="100%"
        height="500px"
        :options="options"
      />
      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          variant="outline-secondary"
          @click="undo()"
        >
          <span class="text-white text-nowrap font-medium-2">Annuler</span>
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="save()"
        >
          <div v-if="isContractFileDownloading">
            <span> Chargement ... </span>
            <b-spinner small />
          </div>
          <span
            v-else
            class="text-white text-nowrap font-medium-2"
          >Valider</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BEmbed, BButton, BCardText, BLink, BCard, BSpinner, BBreadcrumb, BBreadcrumbItem,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BEmbed,
    BCard,
    BButton,
    BCardText,
    BLink,
    BSpinner,
    BBreadcrumb,
    BBreadcrumbItem,
  },

  data() {
    return {
      routeQuery: null,
      isContractFileDownloading: false,
      isError: false,
    }
  },

  computed: {
    pdfLink() {
      return localStorage.getItem('contrat') || localStorage.getItem('attestation')
    },

    options() {
      return {
        throttle: 30,
        minDistance: 5,
        backgroundColor: 'rgba(0,0,0,0)',
        penColor: 'black',
        onBegin: () => {
          this.$refs.signaturePad.resizeCanvas()
        },
      }
    },
  },

  created() {
    this.routeQuery = this.$route.query
    console.log('this.routeQuery', this.routeQuery)
  },

  mounted() {
    if (Object.hasOwn(this.$route.params, 'orderId') && !this.$route.params.orderId) {
      this.isError = true
    }
    console.log(this.isError)
  },

  methods: {
    ...mapActions('professionals', ['signContratAction', 'signBusinessContratAction']),

    modifyFeedback() {
      this.$router.push({
        name: 'admin-direct-order-modify-feedback',
        params: { id: this.$route.query.orderId },
        query: { packageId: this.$route.query.packageId, service: this.$route.query.service },
      })
    },

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    undo() {
      this.$refs.signaturePad.undoSignature()
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      const file = this.dataURLtoFile(data, 'hello.png')
      if (!isEmpty) {
        this.isContractFileDownloading = true
        if (this.$route.query.contratType == 'business') {
          this.signBusinessContratAction({
            orderId: this.routeQuery.orderId,
            employeeId: this.routeQuery.employeeId,
            payload: {
              employee_signature: file,
            },
          })
            .then(response => {
              console.log('contrat', response.data)
              this.isContractFileDownloading = false
              this.$router.push({
                path: '/preview/pdf',
                query: {
                  makeActionOnPage: false, actionType: null, orderId: this.$route.params.orderId, employeeId: this.$route.params.employeeId, contratType: 'business', pdf: response.data.employee_contrat_file_name,
                },
              })
              this.$bvModal.hide('modal-sign-contrat')
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$router.go()
            })
            .catch(error => {
              this.isContractFileDownloading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        } else {
          this.signContratAction({
            propositionId: this.routeQuery.propositionId,
            payload: {
              employee_signature: file,
            },
          })
            .then(response => {
            // localStorage.setItem('contrat', response.data)
              this.isContractFileDownloading = false
              this.$router.push({
                path: '/preview/pdf',
                query: {
                  makeActionOnPage: false, actionType: null, propositionId: this.$route.params.propositionId, pdf: response.data,
                },
              })
              this.$bvModal.hide('modal-sign-contrat')
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$router.go()
            })
            .catch(error => {
              this.isContractFileDownloading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Attention',
              icon: 'AlertTriangleIcon',
              text: 'Merci de signer le contrat',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      }
    },
  },
}
</script>
